import img1 from '../images/gym/1.JPG';
import img2 from '../images/gym/2.JPG';
// import img3 from '../images/gym/3.jpg';
// import img4 from '../images/gym/4.JPG';
import img5 from '../images/gym/5.JPG';
import img6 from '../images/gym/6.JPG';
import img7 from '../images/gym/7.JPG';
import img8 from '../images/gym/8.JPG';
import img9 from '../images/gym/9.JPG';
import img10 from '../images/gym/10.JPG';
import img11 from '../images/gym/11.JPG';
// import img12 from '../images/gym/12.JPG';
// import img13 from '../images/gym/13.JPG';
// import img14 from '../images/gym/14.JPG';

export const gridGallery = [
  img1,
  img2,
  // img3,
  // img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  // img12,
  // img13,
  // img14,
];
