import p_brichta01 from '../images/aboutus/p_brichta01.jpg'
import p_brichta02 from '../images/aboutus/p_brichta02.jpg'
import p_brichta03 from '../images/aboutus/p_brichta03.jpg'
// import p_brichta04 from '../images/aboutus/p_brichta04.jpg'
import p_czfighters_bechtold from '../images/aboutus/p_czfighters_bechtold.jpg'
import p_czfighters_eldar from '../images/aboutus/p_czfighters_eldar.jpg'
import p_daylistyle_loncak from '../images/aboutus/p_daylistyle_loncak.jpg'
import p_extra_viktor from '../images/aboutus/p_extra_viktor.jpg'
import p_fcn_kozma1 from '../images/aboutus/p_fcn_kozma1.jpg'
import p_fcn_kozma2 from '../images/aboutus/p_fcn_kozma2.jpg'
import p_isport_eldar1 from '../images/aboutus/p_isport_eldar1.jpg'
import p_keonline_emin from '../images/aboutus/p_keonline_emin.jpg'
import p_kozmma01 from '../images/aboutus/p_kozmma01.jpg'
// import p_kozmma02 from '../images/aboutus/p_kozmma02.jpg'
import p_kozmma03 from '../images/aboutus/p_kozmma03.jpg'
import p_kozmma04 from '../images/aboutus/p_kozmma04.jpg'
import p_kozmma05 from '../images/aboutus/p_kozmma05.jpg'
import p_kozmma06 from '../images/aboutus/p_kozmma06.jpg'
// import p_kozmma07 from '../images/aboutus/p_kozmma07.jpg'
import p_kozmma08 from '../images/aboutus/p_kozmma08.jpg'
// import p_kozmma09 from '../images/aboutus/p_kozmma09.jpg'
import p_kozmma10 from '../images/aboutus/p_kozmma10.jpg'
import p_kozmma11 from '../images/aboutus/p_kozmma11.jpg'
import p_kozmma12 from '../images/aboutus/p_kozmma12.jpg'
import p_kozmma13 from '../images/aboutus/p_kozmma13.jpg'
import p_kozmma14 from '../images/aboutus/p_kozmma14.jpg'
import p_kozmma15 from '../images/aboutus/p_kozmma15.jpg'
import p_kozmma16 from '../images/aboutus/p_kozmma16.jpg'
// import p_kozmma17 from '../images/aboutus/p_kozmma17.jpg'
import p_lui from '../images/aboutus/p_lui.jpg'
import p_one_viktor1 from '../images/aboutus/p_one_viktor1.jpg'
import p_sport_kozma3 from '../images/aboutus/p_sport_kozma3.jpg'
// import p_sportcz_1098655 from '../images/aboutus/p_sportcz_1098655.jpeg'
import p_svetmma_emin1 from '../images/aboutus/p_svetmma_emin1.jpg'

const about = [
  {
    id: 1,
    photo: p_isport_eldar1,
    alt: 'p_isport_eldar1',
    date: '02.08.2017',
    baseUrl: 'fit-pro.cz',
    url: 'https://www.fit-pro.cz/magazin/eldar-yakuza-rafigaev-a-ben-cristovao-v-jiu-jitsu-se-muzete-neustale-rozvijet',
    heading: 'Eldar "Yakuza" Rafigaev a Ben Cristovao',
    shortDescription:
      'Potkat tyto dva muže, kteří neustále cestují po celém světě na jednom místě, je velmi obtížný úkol. Nicméně osud tomu chtěl, a v rámci tréninkového dne...',
  },
  {
    id: 2,
    photo: p_svetmma_emin1,
    alt: 'p_svetmma_emin1',
    date: '08.08.2017',
    baseUrl: 'svetmma.sk',
    url: 'http://svetmma.sk/clanok/740',
    heading: 'Emin Sefa je po ročnej pauze späť',
    shortDescription:
      'East Pro Fight spojil sily s Fusion FN a pripravil pre divákov v poradí už desiate gala. V Košiciach sa budú rozdávať opasky a o jeden z nich zabojuje aj Emin Sefa...',
  },
  {
    id: 3,
    photo: p_czfighters_bechtold,
    alt: 'p_czfighters_bechtold',
    date: '09.10.2017',
    baseUrl: 'czechfighters.cz',
    url: 'http://www.czechfighters.cz/2017/10/09/pavel-bechtold-od-hasi-prisla-nabidka-ktera-se-neodmita/',
    heading: 'Pavel Bechtold: Od Háši přišla nabídka, která se neodmítá',
    shortDescription:
      'Pavel Bechtold je dalším zápasníkem, který 12. října nastoupí na turnaji GCF 41: Night of the Legends ke svému poslednímu zápasu. Pavel v případě výhry...',
  },
  {
    id: 4,
    photo: p_czfighters_eldar,
    alt: 'p_czfighters_eldar',
    date: '10.11.2017',
    baseUrl: 'czechfighters.cz',
    url: 'http://www.czechfighters.cz/2017/11/10/eldar-rafigaev-zkusim-mu-vratit-ten-triangl-co-dal-martinovi/',
    heading: 'Eldar Rafigaev: Zkusím mu vrátit ten triangl, co dal Martinovi',
    shortDescription:
      'Fenomenální zápasník BJJ Eldar Rafigaev se v Liberci na Night of Warriors opět vrací do klece, kde ho v souboji MMA bude čekat francouzský Prince...',
  },
  {
    id: 5,
    photo: p_one_viktor1,
    alt: 'p_one_viktor1',
    date: '22.12.2017',
    baseUrl: 'www.one.cz',
    url: 'https://www.onetv.cz/detail/hvezda-ceskeho-mma-viktor-pavlicek-predvede-tvrdy-zapas-v-k1-pak-se-vrati-zpet-do-oktagonu',
    heading: 'Hvězda českého MMA Viktor Pavlíček předvede tvrdý zápas v K1',
    shortDescription:
      'Do studia eXtra.cz přijala pozvání jedna z největších hvězd českého MMA Viktor Pavlíček (32). V exkluzivním rozhovoru prozradil, že se otestuje v jiné bojové...',
  },
  {
    id: 6,
    photo: p_extra_viktor,
    alt: 'p_extra_viktor',
    date: '28.12.2017',
    baseUrl: 'extra.cz',
    url: 'https://www.extra.cz/tohle-je-nejdesivejsi-bojovnik-ceska-a-ted-chce-dobyt-prazskou-lucernu',
    heading:
      'Tohle je nejděsivější bojovník Česka! A teď chce dobýt pražskou Lucernu',
    shortDescription:
      'Viktor Pavlíček (32) váží 115 kilogramů. Zápasí v nejbrutálnějším bojovém umění na světě - v MMA. A drží v něm pás v nejvyšší váhové kategorii v...',
  },
  {
    id: 7,
    photo: p_lui,
    alt: 'p_lui',
    date: '15.03.2018',
    baseUrl: 'lui.cz',
    url: 'https://www.lui.cz/co-se-deje/item/12968-rozhovor-seda-eminence-bojovych-sportu-marek-loncak-trener-by-mel-mit-vseobecne-respekt-at-uz-u-klasickeho-zavodnika-tak-u-lidi-z-vip-sektoru',
    heading: 'Marek Lončák: "Trenér by měl mít všeobecně respekt"',
    shortDescription:
      'Uznávaný trenér Marek Lončák (42) se narodil a žil v Kolíně. Až před sedmi lety se kvůli kariéře a pracovní vytíženosti  přestěhoval do Prahy. Živí se jako trenér...',
  },
  {
    id: 8,
    photo: p_sport_kozma3,
    alt: 'p_sport_kozma3',
    date: '17.03.2018',
    baseUrl: 'sport.cz',
    url: 'https://www.sport.cz/ostatni/bojove-sporty/clanek/972307-kozma-ukazal-tvrde-lokty-ostravu-bavily-bitvy-v-kleci.html',
    heading: 'Kozma ukázal tvrdé lokty, Ostravu bavily bitvy v kleci',
    shortDescription:
      'Další porce bojových umění, MMA si získává více a více fanoušků. V sobotu večer byly k vidění zápasy v Ostravě na galavečeru Oktagon 5. Sport.cz nabídl...',
  },
  {
    id: 9,
    photo: p_fcn_kozma2,
    alt: 'p_fcn_kozma2',
    date: '25.03.2018',
    baseUrl: 'fightclubnews.cz',
    url: 'http://fightclubnews.cz/david-kozma-ve-finale-pyramidy-nechci-nikoho-jineho-nez-gabora/',
    heading:
      'David Kozma: „Ve finále pyramidy nechci nikoho jiného než Gábora“',
    shortDescription:
      'Nejen Miloš Petrášek má za sebou úspěšnou premiéru pod vlajkami organizace OKTAGON MMA. V Ostravě, proti velmi nebezpečnému soupeři, zazářil i rodák z...',
  },
  {
    id: 10,
    photo: p_daylistyle_loncak,
    alt: 'p_daylistyle_loncak',
    date: '15.04.2018',
    baseUrl: 'dailystyle.cz',
    url: 'http://dailystyle.cz/marek-loncak-u-nas-v-gymu-potkate-spoustu-zen-ktere-se-aktivne-pripravuji-na-plavkovou-sezonu/',
    heading:
      'U nás v gymu potkáte spoustu žen, které se aktivně připravují na plavkovou sezónu.',
    shortDescription:
      'Příprava na plavkovou sezónu neminula ani naši redakci. Při hledání nových, zajímavých možností, jak se vrátit zpět do formy a získat kondici, jsme narazili...',
  },
  {
    id: 11,
    photo: p_keonline_emin,
    alt: 'p_keonline_emin',
    date: '28.04.2018',
    baseUrl: 'kosiceonline.sk',
    url: 'https://www.kosiceonline.sk/kosice-zazili-galavecer-tvrdych-ran-a-krasnych-zien-domaci-milacik-sefa-doplatil-na-zranenie',
    heading: 'Košice zažili galavečer tvrdých rán a krásnych žien.',
    shortDescription:
      'Vypredaná Angels aréna v Košiciach zažila v piatok večer jedenástu edíciu galavečera bojovníkov East Pro Fight, ktorá sa už tentoraz uskutočnila pod...',
  },
  {
    id: 12,
    photo: p_fcn_kozma1,
    alt: 'p_fcn_kozma1',
    date: '03.05.2018',
    baseUrl: 'fightclubnews.cz',
    url: 'http://fightclubnews.cz/favorit-pyramidy-welterovych-vah-david-kozma-jsem-moc-hladovy-po-titulu/',
    heading:
      'Favorit pyramidy welterových vah David Kozma: „Jsem moc hladový po titulu“',
    shortDescription:
      'Kozmu přijede ověřit ukrajinský specialista: David Kozma (19-10) vs. Alexej Valivakhin (13-9).Na OKTAGON 5 se David před domácím publikem...',
  },
  {
    id: 13,
    photo: p_kozmma16,
    alt: 'p_kozmma16',
    date: '30.09.2018',
    baseUrl: 'seznamzpravy.cz',
    url: 'https://www.seznamzpravy.cz/clanek/naposledy-malem-utrhl-cinanovi-hlavu-sezeru-ho-vzkazuje-i-tak-zapasnik-kozma-pred-finale-oktagonu-56652',
    heading:
      'Naposledy málem utrhl Číňanovi hlavu. Sežeru ho, vzkazuje i tak zápasník Kozma.',
    shortDescription:
      'Zápasník MMA David Kozma se rozpovídal o náročném vítězství nad maďarským soupeřem i o nadcházející bitvě se Slovákem Borárošem ...',
  },
  {
    id: 14,
    photo: p_brichta01,
    alt: 'p_brichta01',
    date: '16.10.2018',
    baseUrl: 'czechfighters.cz',
    url: 'http://www.czechfighters.cz/2018/10/16/leo-brichta-pockam-co-mi-souper-ukaze-v-kleci/',
    heading: 'Leo Brichta: Počkám, co mi soupeř ukáže v kleci.',
    shortDescription:
      'Český supertalent MMA Leo Brichta se 27. 10. na Fusion FN v Brně vrací po komplikovaném zranění do klece. Jeho soupeř bude německý... ',
  },
  {
    id: 15,
    photo: p_brichta03,
    alt: 'p_brichta03',
    date: '17.10.2018',
    baseUrl: 'top-fight.cz',
    url: 'https://top-fight.cz/Ceska-scena/souperi-treste-se:-leo-brichta-je-po-zraneni-zpet-/448/',
    heading: 'Soupeři, třeste se: Leo Brichta je po zranění zpět!.',
    shortDescription:
      'Je to nějaký ten pátek, kdy Hanuman Gym a Petra Macháčka opustil talent českého MMA Leo Brichta. Následně se přemístil pod vlajku Primmat...',
  },
  {
    id: 16,
    photo: p_brichta02,
    alt: 'p_brichta02',
    date: '20.10.2018',
    baseUrl: 'sport.cz',
    url: 'https://www.sport.cz/ostatni/bojove-sporty/clanek/1032751-pozor-vraci-se-drzy-talent-dostane-zaplaceno-za-dobre-odvedenou-praci.html',
    heading:
      'Pozor! Vrací se drzý talent. Dostane zaplaceno za dobře odvedenou práci.',
    shortDescription:
      'Je zpátky. Soupeři i promotéři se dozví, v jaké formě se vrátí po nucené zdravotní pauze. Jedenadvacetiletý talent českého MMA Leo Brichta... ',
  },
  {
    id: 17,
    photo: p_kozmma06,
    alt: 'p_kozmma06',
    date: '21.11.2018',
    baseUrl: 'iluxus.cz',
    url: 'https://iluxus.cz/2018/11/21/david-kozma-ovladl-pas-sampiona-ve-welterove-vaze/',
    heading:
      'Kozma ovládl pás šampiona ve weltrové váze v pouchých dvaceti šesti letech.',
    shortDescription:
      'V pouhých dvaceti šesti letech se David Kozma, přezdívaný "Růžový panter" stal šampionem České republiky a Slovenska ve welterové... ',
  },
  {
    id: 18,
    photo: p_kozmma15,
    alt: 'p_kozmma15',
    date: '05.02.2019',
    baseUrl: 'extra.cz',
    url: 'https://www.extra.cz/tohle-je-10-nejhezcich-kolousku-mma-kouknete-na-ty-sexy-svaly-galerie',
    heading:
      'Tohle je 10 nejhezčích koloušků MMA: Koukněte na ty sexy svaly! GALERIE.',
    shortDescription:
      'Přestože jejich tváře neustále schytávají rány a jejich vzhled je mnohdy to poslední, co je zajímá. Vybrali jsme pro vás DESET zápasníků MMA...',
  },
  {
    id: 19,
    photo: p_kozmma05,
    alt: 'p_kozmma05',
    date: '12.03.2019',
    baseUrl: 'czechfighters.cz',
    url: 'http://www.czechfighters.cz/2019/03/12/david-kozma-pirate-tes-se-do-ostravar-areny/',
    heading: 'David Kozma: Piráte, těš se do Ostravar arény!.',
    shortDescription:
      'První titulová obhajoba čeká tuto sobotu na Oktagon 11 v Ostravě šampiona Davida Kozmu. Jeho vyzyvatel Samuel Pirát Krištofič si brousí zuby... ',
  },
  {
    id: 20,
    photo: p_kozmma14,
    alt: 'p_kozmma14',
    date: '15.03.2019',
    baseUrl: 'idnes.cz',
    url: 'https://www.idnes.cz/sport/ostatni/david-kozma-oktagon-mma-kristofic-ostrava-mma.A190314_161141_sporty_rmp',
    heading: 'Pan Klidný. Zlodějů se neboj, učili českého šampiona MMA odmala.',
    shortDescription:
      'Je prototypem klidné síly. Nenechá se rozhodit, sám zároveň nemá potřebu nikomu nadávat. „Když to zkouším, vypadám směšně,“ tvrdí elitní... ',
  },
  {
    id: 21,
    photo: p_kozmma04,
    alt: 'p_kozmma04',
    date: '17.03.2019',
    baseUrl: 'lidovky.cz',
    url: 'https://www.lidovky.cz/sport/ostatni-sporty/hlidac-domu-bude-obhajovat-prestizni-mma-titul-na-slovenskeho-soka-plivat-nechce.A190314_195220_ln-sport-ostatni_rkj',
    heading:
      'Hlídač domů bude obhajovat prestižní MMA titul. Na slovenského soka plivat nechce.',
    shortDescription:
      'Je prototypem klidné síly. Nenechá se rozhodit, sám zároveň nemá potřebu nikomu nadávat. „Když to zkouším, vypadám směšně,“ tvrdí... ',
  },
  {
    id: 22,
    photo: p_kozmma13,
    alt: 'p_kozmma13',
    date: '17.04.2019',
    baseUrl: 'top-fight.cz',
    url: 'https://top-fight.cz/Videa/rozhovor:-david-kozma/280/',
    heading:
      'David „Růžový Panter“ Kozma je elitní zápasník MMA ve velterové váze.',
    shortDescription:
      'David „Růžový Panter“ Kozma je elitní zápasník MMA ve velterové váze (do 77 kg) a držitel titulu šampiona organizace OKTAGON MMA. Pás šampiona obhájil... ',
  },
  {
    id: 23,
    photo: p_kozmma03,
    alt: 'p_kozmma03',
    date: '17.04.2019',
    baseUrl: 'top-fight.cz',
    url: 'https://top-fight.cz/Videa/rozhovor:-david-kozma/280/',
    heading:
      'Rozhovor: David Kozma. Zápasník Oktagon MMA David Kozma se chystá na bitvu v Rusku.',
    shortDescription:
      'Nyní vypráví o tom, jak se na samotný fight připravuje a jestli se v Rusku nějakou dobu zdrží. V neposlední řadě také prozradil, jak vypadá jeho klasický den... ',
  },
  {
    id: 24,
    photo: p_kozmma12,
    alt: 'p_kozmma12',
    date: '30.04.2019',
    baseUrl: 'sport.cz',
    url: 'https://www.sport.cz/ostatni/bojove-sporty/clanek/1098655-cesky-valecnik-chce-pokorit-rusa-penize-neresi-pry-jsou-ale-slusny.html',
    heading:
      'Český válečník chce pokořit Rusa. Peníze neřeší, prý jsou ale slušný',
    shortDescription:
      'Ruská klec je za dveřmi. Ve čtvrtek v jednu ráno odletí směr východ a v sobotu se porve v Čeljabinsku. Zápasník MMA David Kozma vyráží bojovat pod... ',
  },
  {
    id: 25,
    photo: p_brichta02,
    alt: 'p_brichta02',
    date: '02.05.2019',
    baseUrl: 'sport.cz',
    url: 'https://www.sport.cz/ostatni/bojove-sporty/clanek/1098626-masina-se-vraci-jak-citim-krev-chci-soupere-zabit-rika-s-nadsazkou.html',
    heading:
      'Mašina se vrací. Jak cítím krev, chci soupeře zabít, říká s nadsázkou.',
    shortDescription:
      'Půl roku nemohl chodit, měl dráty v noze a přetrhané šlachy. Teď už český talent MMA funguje a je připraven se vrátit. Tělo je v pořádku ... ',
  },
  {
    id: 26,
    photo: p_kozmma11,
    alt: 'p_kozmma11',
    date: '03.05.2019',
    baseUrl: 'czechfighters.cz',
    url: 'href="http://www.czechfighters.cz/2019/05/03/david-kozma-se-chysta-ukoncit-ruskeho-shurkeviche-ukoncit-pred-limitem/"',
    heading:
      'David Kozma se chystá ukončit ruského Shurkeviche ukončit před limitem.',
    shortDescription:
      'David Kozma má naváženo na zítřejší RCC, kde ho v kleci bude čekat domácí Alexey Shurkevich. V sázce není jen další výhra v řadě, ale...',
  },
  {
    id: 27,
    photo: p_kozmma01,
    alt: 'p_kozmma01',
    date: '03.05.2019',
    baseUrl: 'isport.blesk.cz',
    url: 'https://isport.blesk.cz/clanek/ostatni-mma/362902/vyzva-sampiona-oktagonu-kozma-bude-bojovat-o-kontrakt-v-rusku.html',
    heading: 'Výzva šampiona OKTAGONU. Kozma bude bojovat o kontrakt v Rusku',
    shortDescription:
      'Česko-ruská válka v kleci se blíží. Šampion OKTAGON MMA ve veltrové váze David Kozma se bude v sobotu 4. května bít s Rusem Alexejem...',
  },
  {
    id: 28,
    photo: p_kozmma10,
    alt: 'p_kozmma10',
    date: '04.05.2019',
    baseUrl: 'isport.blesk.cz',
    url: 'https://isport.blesk.cz/clanek/ostatni-mma/362948/krvava-bitka-v-rusku-sampion-kozma-ukoristil-vyhru-na-turnaji-rcc.html',
    heading:
      'Krvavá bitka v Rusku! Šampion Kozma ukořistil výhru na turnaji RCC',
    shortDescription:
      'Zahraniční mise splněna! Tak může šampion organizace OKTAGON MMA ve veltrové váze David Kozma hodnotit výjezd do ruského Čeljabinsku...',
  },
  {
    id: 29,
    photo: p_kozmma08,
    alt: 'p_kozmma08',
    date: '04.05.2019',
    baseUrl: 'sport.cz',
    url: 'https://www.sport.cz/ostatni/bojove-sporty/clanek/1100147-ruzovy-panter-zvladl-ruskou-vyzvu-kozma-ale-neskoncil-bez-sramu.html',
    heading: 'Růžový panter zvládl ruskou výzvu. Kozma ale neskončil bez šrámu',
    shortDescription:
      'Nastoupil v růžových brýlích, tak jak se sluší na Růžového pantera. Po zápase si je mohl opět nasadit. Zápasník MMA David Kozma svoji premiéru v ruské...',
  },
]
export default about
