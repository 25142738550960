const priceList = [
  {
    id: 1,
    name: 'jednorázový vstup',
    price: 210,
  },
  {
    id: 2,
    name: 'detský vstup',
    price: 100,
  },
  {
    id: 3,
    name: 'box oleg',
    price: 250,
  },
  {
    id: 4,
    name: 'fitness měsíčni ',
    price: 1800,
  },
  {
    id: 5,
    name: '10 - vstupová',
    price: 1800,
  },
  {
    id: 6,
    name: 'měsíčni na vše',
    price: 2250,
  },
];
export default priceList;
